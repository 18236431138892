<template>
  <div class="book-detail">
    <p class="title">基于流程和战略的组织设计</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书是介绍企业的如何基于企业战略方向和管理流程进行组织设计，以及组织设计的方法和原则，以促使企业达到战略目标。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >本书编写历时两年多，呈现在读者面前的已经是第9次修改稿，并且笔者相信，当笔者看到正式出版后的书时，肯定又会有许多不满意之处。究其原因，不仅仅是自身对内容的追求，更重要的是外部环境的变化确实太快了——现实中一些曾经辉煌过的公司的破产以及更多新公司的迅速崛起，所有这些都在无情地冲击着企业管理的权威理论，使人们不得不重新认识我们的内外部环境及所面临的挑战。在这种背景下，对组织设计的理论和方法也必须再度审视，以适应甚至于超越社会和经济条件的变化，这也是本书的出发点。</span
      >
      <br />
      <span>
        组织设计不是新概念，但在经历了20世纪末和21世纪初不断发生的公司危机之后，股东、社会和员工们都从不同角度关注着企业的组织设计，什么样的组织结构和组织运行模式才能同时保证安全、高效和创造价值？事实上，这一切的基础都依赖于核心流程，如果说和传统的组织设计有什么不同的话，那么其关键性区别就是是否关注核心流程，而核心流程的根本标志就是能否为顾客创造价值，若不能为顾客创造价值，那么一个组织就不会有真正的长远利益。
      </span>
      <br />
      <span>
        所以，要追求价值最大化或者狭义的利益最大化，关键在于核心流程本身的结构和优化。离开了核心流程，组织设计就不可能是最优的，由此而产生的管理系统自然不可能满足安全、高效和价值创造的要求。
      </span>
      <br />
      <br />
      <div class="divp">
        <span>本书分为3篇，12个章节：</span>
        <p>
          <span>第一篇</span><span style="margin-right: 20px;">核心流程 </span><span>关注价值创造</span>
        </p>
        <p><span>第1章</span><span>发展之本— —探索核心竞争力</span></p>
        <p><span>第2章</span><span>认识流程— —特征及其分析工具</span></p>
        <p><span>第3章</span><span>和信念流程甘— —识别、描述和改进</span></p>
        <p><span>第4章</span><span>流程管理— —优化永无止境</span></p>
        <p>
          <span>第一篇</span><span style="margin-right: 20px;">战略管理 </span><span>切勿迷失方向</span>
        </p>
        <p><span>第5章</span><span>解读战略— —视角的思考</span></p>
        <p><span>第6章</span><span>战略基础— —加开SWOT分析的面纱</span></p>
        <p><span>第7章</span><span>制定和实施战略——克服变革的阻力</span></p>
        <p>
          <span>第三篇</span><span style="margin-right: 20px;">组织设计 </span><span>基于流程和战略</span>
        </p>
        <p><span>第8章</span><span>基础背景——为什么要设计组织</span></p>
        <p><span>第9章</span><span>卓越领导——组织创新的灵魂</span></p>
        <p><span>第10章</span><span>组织结构——规模、技术和信息</span></p>
        <p><span>第11章</span><span>设计原则——注重可操作性</span></p>
        <p><span>第12章</span><span>程序要点——现实中的组织设计</span></p>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>